import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from '@app/core/backend.service';
import { HpiService } from '@app/core/hpi.service';
import { HighlightSearchPipe } from '@app/pipes/highlight-search-pipe/highlight-search.pipe';
import { EChartsOption, BarSeriesOption } from 'echarts';
import { IHpiDocument, IIngredient, TScienceOrSocial } from '@models/hpi.model'
import { Subscription } from 'rxjs';
import { set } from 'date-fns';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  scoreBarOptions: EChartsOption;
  scoreBarLoading = true;

  docCountBarOptions: EChartsOption;
  docCountBarLoading = true;

  ingredientConcerns: {[k: string]: {[concern: string]: number}} = {};

  topPositiveOpportunities: any[]
  topNegativeOpportunities: any[];

  numPositiveSocial = 0;
  numPositiveScience = 0;
  numNegativeSocial = 0;
  numNegativeScience = 0;
  numNegativeTotal = 0;
  numPositiveTotal = 0;
  numNegativeLastYearTotal = 0;
  numPositiveLastYearTotal = 0;
  numNegativeDelta = 0;
  numPositiveDelta = 0;

  totalIngredients = 0;

  topDocument: IHpiDocument;

  parallelOptions: EChartsOption;
  parallelLoading = true;

  sub: Subscription

  fastGrowing;

  abstractExpanded = false;

  constructor(
    public hpi: HpiService,
    private router: Router,
    private route: ActivatedRoute,
    private backend: BackendService,
  ) {
    if (window.innerWidth < 768) {
      const dialogRef = this.hpi.openMobilePopup();
    }
  }

  ngOnInit(): void {
    this.backend.post_route('fastest_growing').then(res => {
      this.fastGrowing = res as any[];
    });
    this.backend.post_route<{
      total_negative: number,
      total_positive: number,
      records: {science_or_social: 'science' | 'social' | 'all', count: number, score: number}[]
    }>('portfolio_health').then(response => {
      console.log('portfolio health response', response)
      const res = response.records;
      this.totalIngredients = res.filter(i => i.science_or_social === 'all')[0].count;
      this.numNegativeTotal = response.total_negative;
      this.numPositiveTotal = response.total_positive;
      // this.numNegativeTotal = res.filter(i => (i.score > 3) && (['science','social'].includes(i.science_or_social))).reduce((partialSum, a) => partialSum + a.count, 0);
      // this.numPositiveTotal = res.filter(i => (i.score === 1) && (['science','social'].includes(i.science_or_social))).reduce((partialSum, a) => partialSum + a.count, 0);
      this.numNegativeLastYearTotal = res.filter(i => (i.score > 3) && (['science_1y','social_1y'].includes(i.science_or_social))).reduce((partialSum, a) => partialSum + a.count, 0);
      this.numPositiveLastYearTotal = res.filter(i => (i.score === 1) && (['science_1y','social_1y'].includes(i.science_or_social))).reduce((partialSum, a) => partialSum + a.count, 0);
      this.numNegativeDelta = Math.abs(100.0*(this.numNegativeTotal - this.numNegativeLastYearTotal)/(this.numNegativeLastYearTotal==0 ? 1.0 : this.numNegativeLastYearTotal));
      this.numPositiveDelta = Math.abs(100.0*(this.numPositiveTotal - this.numPositiveLastYearTotal)/(this.numPositiveLastYearTotal==0 ? 1.0 : this.numPositiveLastYearTotal));
      this.numPositiveScience = res.filter(i => i.science_or_social === 'science' && i.score === 1).length ? res.filter(i => i.science_or_social === 'science' && i.score === 1)[0].count : 0;
      this.numNegativeScience = res.filter(i => i.science_or_social === 'science' && i.score > 3).reduce((partialSum, a) => partialSum + a.count, 0);
      this.numPositiveSocial = res.filter(i => i.science_or_social === 'social' && i.score === 1).length ?  res.filter(i => i.science_or_social === 'social' && i.score === 1)[0].count : 0;
      this.numNegativeSocial = res.filter(i => i.science_or_social === 'social' && i.score > 3).reduce((partialSum, a) => partialSum + a.count, 0);
      this.constructScoreBar(res.filter(i => ['science','social'].includes(i.science_or_social)));
    });
    this.backend.post_route<any>('top_positive_opportunities').then(res => {
      this.topPositiveOpportunities = res.map(r => ({
        ...r,
        num_effects: r.effects[0].num_effects,
        eff_ids: r.effects.map(e => e.eff_id)
      }));
    });
    this.backend.post_route<any>('top_negative_opportunities').then(res => {
      this.topNegativeOpportunities = res.map(r => ({
        ...r,
        num_effects: r.effects[0].num_effects,
        eff_ids: r.effects.map(e => e.eff_id)
      }));
    });
    this.backend.post_route<IHpiDocument>('most_influental_document').then(res => {
      this.topDocument = res;
      if (this.topDocument.date) {
        this.topDocument.date = new Date(this.topDocument.date);
      }
    })
  }

  expandAbstract() {
    this.abstractExpanded = !this.abstractExpanded;
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  clickCell(ing: IIngredient, science_or_social: TScienceOrSocial, sentiment: 'positive' | 'negative') {
    let startDate = new Date();
    startDate = set(startDate, {
      year: 2010,
      month: 0,
      date: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0
    });
    this.hpi.openDocDialog({
      ing_ids: [ing.ing_id],
      science_or_social,
      sentiment,
      title: `${ing.ingredient} Documents`,
      start_date: startDate.getTime() / 1000,
    })
  }

  async constructScoreBar(scoreBarData: {science_or_social: 'science' | 'social' | 'all', count: number, score: number}[]) {
    console.log('score bar data', scoreBarData);
    const scoreValues = [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]];
    scoreBarData.forEach(d => {
      if (d.score > 0) {
        scoreValues[d.score - 1][['science','social'].indexOf(d.science_or_social)] = d.count;
      }
    })
    const legendValues = ['Positive','Neutral','Caution','Concerning','Negative']
    const colors = this.hpi.scoreColors.slice(1);
    const series: BarSeriesOption[] = scoreValues.map((s, idx) => {
      return {
        name: legendValues[idx],
        color: colors[idx],
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
        },
        barWidth: 30,
        emphasis: {
          focus: 'series',
        },
        data: s
      }
    });
    this.scoreBarOptions = {
      // title: {
      //   text: 'Score Breakdown'
      // },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // Use axis to trigger tooltip
          type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        }
      },
      legend: {
        top: 'bottom'
      },
      grid: {
        top: '3%',
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        show: false,
        type: 'value'
      },
      yAxis: {
        type: 'category',
        data: ['Science','Market']
      },
      series
    }
    this.scoreBarLoading = false;
  }

  async constructDocCountBar(docCountBarData: {ingredient: string, science: number, social: number}[]) {
    console.log('doc count bar data', docCountBarData);
    const docTypeMap = {science: 'Science', social: 'Market'}
    const series: BarSeriesOption[] = ['science','social'].map(doctype => {
      return {
        name: docTypeMap[doctype],
        type: 'bar',
        stack: 'total',
        label: {
          show: false,
          formatter: function(d) {return d.data.toLocaleString()},
        },
        emphasis: {
          focus: 'series'
        },
        data: docCountBarData.map(i => i[doctype])
      } 
    })
    this.docCountBarOptions = {
      dataZoom:[ {
        type: 'inside',
        id: 'insideY',
        yAxisIndex: 0,
        handleSize: 5,
        start: 50,
        end: 100,
        zoomOnMouseWheel: false,
        moveOnMouseMove: true,
        moveOnMouseWheel: true
      }],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // Use axis to trigger tooltip
          type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        }
      },
      legend: {
        top: 'bottom'
      },
      grid: {
        // left: '10',
        top: 0,
        // right: '4%',
        // bottom: '3%',
        containLabel: true
      },
      xAxis: {
        show: true,
        type: 'value'
      },
      yAxis: {
        type: 'category',
        data: docCountBarData.map(i => i.ingredient),
      },
      series,
    }

    this.docCountBarLoading = false;
  }

  clickIngredient(ing_id: string) {
    console.log("in click ingredient", ing_id);
    this.hpi.selectIngredient(ing_id);
    this.router.navigate(['/ingredients','summary'])
  }

  clickDoc(doc: IHpiDocument) {
    if (doc.links.length) {
      window.open(doc.links[0], '_blank')
    }
  }

  clickContact() {
    this.router.navigate(['/appendix','support'])
  }


}
