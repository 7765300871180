// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  build_env: 'dev',
  backendUrl: 'https://dev.api.northai.io',
  // backendUrl: 'https://hpi-api-eb-dev.chn-api.com',
  // backendUrl: 'https://munch.api.chnanalytics.com:5000',
  // typesenseApiKey: 'ta4qNw5Qm9bAaqrFs5faoyge7T91qgjLAQZIvKobNZMxvZC3',
  firebase: {
    apiKey: "AIzaSyBfhx0LwIPsnhnf76GgnQ7hss9EFLtqUe0",
    authDomain: "hpi-app-dev.firebaseapp.com",
    projectId: "hpi-app-dev",
    storageBucket: "hpi-app-dev.appspot.com",
    messagingSenderId: "131320708736",
    appId: "1:131320708736:web:d49103219105b5ff69b065",
    measurementId: "G-V97TL9F1Q7"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
