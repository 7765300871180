<div class="app-wrapper">
  <div class="hpi-navbar">
    <div class="logo-container">
      <img class="logo" [routerLink]="['/']" src="assets/4sight_logo.png">
    </div>
    <div class="tabs-container">
      <div *ngFor="let link of links"
        (click)="userDidClickTab(link.route)"
        [ngClass]="{'navbar-tab': true, 'active': router.url.includes('/' + link.route)}"
      >
        <span class="tab-text">{{link.label}}</span>
        <div class="border-box"></div>
      </div>
      <!-- <nav class="tabs-nav" mat-tab-nav-bar [tabPanel]="tabPanel" mat-align-tabs="center">
        <a class="navbar-tab" mat-tab-link *ngFor="let link of links" (click)="userDidClickTab(link.route)" [active]="router.url.includes('/' + link.route)">{{link.label}}</a>
      </nav> -->
    </div>
    <div class="right-container">
      <!-- <button *ngIf="isLoggedIn" mat-icon-button class="nav-icon" [matMenuTriggerFor]="quarterlyReportMenu"><mat-icon>assessment</mat-icon></button>
      <mat-menu #quarterlyReportMenu="matMenu">
        <button mat-menu-item (click)="downloadFile()">
          <span>Download Titanium Dioxide Report</span>
        </button>
      </mat-menu> -->
      <button *ngIf="isLoggedIn" mat-icon-button class="nav-icon" [matMenuTriggerFor]="notificationsMenu"><mat-icon>notifications</mat-icon></button>
      <mat-menu #notificationsMenu="matMenu">
        <button mat-menu-item>
          <span>0 Notifications</span>
        </button>
      </mat-menu>
      <!-- <mat-icon class="nav-icon" matBadge="10">notifications</mat-icon> -->
      <button mat-icon-button class="nav-icon" [matMenuTriggerFor]="accountMenu"><mat-icon>account_circle</mat-icon></button>
      <mat-menu #accountMenu="matMenu">
        <button  mat-menu-item *ngIf="isLoggedIn && auth?.currentUser?.account?.isAdmin" [routerLink]="['/admin']">
          <mat-icon>admin_panel_settings</mat-icon>
          <span>Admin Page</span>
        </button>
        <button *ngIf="isLoggedIn" mat-menu-item (click)="signout()">
          <mat-icon>logout</mat-icon>
          <span>Sign out</span>
        </button>
        <button *ngIf="!isLoggedIn"  mat-menu-item [routerLink]="['/auth/login']">
          <mat-icon>login</mat-icon>
          <span>Sign In</span>
        </button>
      </mat-menu>
      
    </div>
  </div>
  <div class="app-container">
    <router-outlet></router-outlet>
  </div>
</div>
<!-- <button (click)="checkStatus()">Status</button> -->

