<div class="chip-list" [class.nowrap]="nowrap" [class.expanded]="expanded">
  <button *ngIf="effects.length > 0" class="expand-btn" (click)="toggleExpand()">
    {{ expanded ? 'Collapse' : 'Expand' }}
  </button>
  <ng-container *ngFor="let eff of effects">
    <ng-container *ngIf="eff.effect?.length && eff?.eff_id && !['no concern'].includes(eff.effect.toLowerCase())">
      <div class="chip-container" (click)="clickEffect(eff)" [class.clickable]="clickable">
        <div class="chip">
          <div class="chip-text">
            {{eff.effect}}
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>