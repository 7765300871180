import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, skipWhile } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Auth, authState } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  jwt: string;
  constructor(
    private http: HttpClient,
    private auth: Auth,
  ) {

  }

  get_jwt(force_refresh=false): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.jwt && !force_refresh) {
        resolve(this.jwt);
        return;
      }

      const timeout = setTimeout(() => {
        reject(new Error('Timeout waiting for user authentication'));
      }, 5000);

      const subscription = authState(this.auth).pipe(
        skipWhile(user => !user)
      ).subscribe({
        next: async (user) => {
          if (user) {
            try {
              const jwt = await user.getIdToken();
              this.jwt = jwt;
              clearTimeout(timeout);
              subscription.unsubscribe();
              resolve(this.jwt);
            } catch (err) {
              clearTimeout(timeout);
              subscription.unsubscribe(); 
              reject(err);
            }
          }
        },
        error: (err) => {
          clearTimeout(timeout);
          subscription.unsubscribe();
          reject(err);
        }
      });
    });
  }

  async getHeaders(noAuth=false) {
    const headers = {
      'Content-type':'application/json', 
      'Accept':'application/json',
      'X-Project-Name': 'hpi',
      'X-Firebase-App-Id': environment.firebase.appId,
      'X-Billing-Account-Id': '',
    }
    if (!noAuth) {
      await this.get_jwt();
      headers['Authorization'] = `Bearer ${this.jwt}`;
    }
    return headers;
  }


  showErrors(err: HttpErrorResponse) {
    console.log('showErrors', err);
    const status = err?.status;
    const detail = err?.error?.detail;
    if (status === 422) {
      // pydantic validation errors
      if (detail?.length) {
        for (let e of detail) {
          let msg = 'Validation Error: ';
          if (e?.loc) {
            msg += e.loc.join('.');
          }
          if (e?.msg) {
            msg += ' -- ' +e.msg;
          }
          console.log(msg);
        }
      }
    } else if (status === 403) {
      console.error('403 error', err);
    } else if (status === 404) {
      console.error('404 error', err);
    } else if (status === 400) {
      if (detail) {
        if (typeof detail === 'string') {
          console.error('400 error', detail);
        } else if (typeof detail === 'object') {
          if (detail.message) {
            console.error('400 error', detail.message);
          }
          if (detail?.alert) {
            alert(detail.alert);
          }
          if (detail?.snack) {
            // this.snack.simple({
            //   type: 'error',
            //   message: detail.snack,
            // })
          }
        } else {
          console.error('400 error', err?.error?.detail);
        }
      } else {
        console.error('400 error', err);
      }
    } else {
      console.log('HTTP ERROR', err.status, err);
    }
  }

  // async post_route<T>(route: string, body = {}) {
  //   await this.get_jwt();
  //   console.log('posting route', route, ' body', body);
  //   const stream = this.http.post(`${environment.backendUrl}/api/hpi/${route}`, body, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${this.jwt}`
  //     }
  //   });
  //   const ret = await lastValueFrom(stream);
  //   console.log('posted route', route, ' response', ret);
  //   return ret as T;
  // }

  async post_route<T>(route: string, body = {}, isRetry=false, noAuth=false): Promise<T> {
    console.log('posting route', route, body)
    const headers = await this.getHeaders(noAuth);
    const stream = this.http.post(`${environment.backendUrl}/api/hpi/${route}`, body, {headers});
    try {
      const ret = await lastValueFrom(stream);
      console.log(route, ' response', ret);
      return ret as T;
    } catch (err) {
      this.showErrors(err);
      if (err.status === 498) {
        await this.get_jwt(true);
        // alert('Your session has expired, attempting to reauthenticate');
        if (!isRetry) {
          return await this.post_route<T>(route, body, isRetry=true);
        } else {
          throw err;
        }
      }
      throw(err);
    }
  }

}
