<div class="social-container" *ngLet="hpi.selectedIngredient$ | async; let sel;">
  <!-- <button type="button" (click)="test()">Test</button> -->
  <ng-container >
    <div class="ing-title">{{sel.ingredient | uppercase}}</div>
    <div class="score-timeline-container">
      <div class="score-container card-container">
        <div class="card-header no-header">
          <div class="card-title">Market Score <img matTooltip="Sentiment Score for news articles involving {{sel.ingredient}}" src="assets/info_icon.png" class="info-icon-small"></div>
        </div>
        <div class="summary-scores card-content">
          <div class="social-score summary-score">
            <app-summary-score [ingredient]="sel" [scienceOrSocial]="'social'" [orient]="'vertical'"></app-summary-score>
            <div class="score-description" *ngLet="sel?.social_score_delta as delta;">
              <ng-container *ngIf="delta > 0">
                Sentiment is trending more negative in recent mews media posts
              </ng-container>
              <ng-container *ngIf="delta < 0">
                Sentiment is trending more postiive in recent news media posts
              </ng-container>
              <ng-container *ngIf="delta === 0">
                Sentiment has not changed in recent news media posts
              </ng-container>
            </div> 

          </div>
        </div>
      </div>
      <div class="timeline-container card-container">
        <div class="card-header no-header">
          <div class="card-title">News Articles Timeline<img matTooltip="Double click on a point to inspect documents" src="assets/info_icon.png" class="info-icon-small"></div>
        </div>
        <div class="card-content timeline-wrapper">
          <app-doc-timeline [scienceOrSocial]="'social'"></app-doc-timeline>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="treemap-docs-container">
    <div class="treemap-container card-container">
      <div class="card-header">
        <div class="card-title">Effects Deep Dive<mat-icon class="tooltip-icon" matTooltip="Effects related to {{(hpi?.selectedIngredient$ | async)?.ingredient}} by sentiment. Double click a box to inspect documents.">info_outline</mat-icon></div>
      </div>
      <div class="card-content">
        <app-effect-tree-map [scienceOrSocial]="'social'"></app-effect-tree-map>
      </div>
    </div>
    <div class="docs-container card-container" *ngIf="documents">
      <div class="card-header docs-header">
        <div class="card-title">
          Recent News Articles/Mentions 
          <mat-icon class="tooltip-icon" matTooltip="Most recent news articles related to {{(hpi?.selectedIngredient$ | async)?.ingredient}}">info_outline</mat-icon>
        </div>
      </div>
      <div class="docs-list card-content">
        <div class="doc" *ngFor="let doc of documents">
          <div [ngClass]="{'title': true, 'with-link': true}" (click)="clickDoc(doc)">
            {{doc.title}}<mat-icon *ngIf="doc?.links?.length">open_in_new</mat-icon>
          </div>
          <div class="subtitle">
            <div class="journal" *ngIf="doc?.journal?.length">{{doc.journal}}</div>
            <mat-icon *ngIf="doc?.journal?.length">chevron_right</mat-icon>
            <div class="date" *ngIf="doc.date">{{doc.date | date : 'longDate'}}</div>
            <mat-icon *ngIf="doc.date">chevron_right</mat-icon>
            <!-- <div class="citations" *ngIf="doc.citations">{{doc.citations}} retweets</div> -->
          </div>
          <div class="concerns">
            <div class="label">Effects:</div>
            <app-effects-chip-list [eff_ids]="doc?.eff_ids" [ing_id]="sel?.ing_id" [nowrap]="false"></app-effects-chip-list>

          </div>
          <div class="abstract">{{doc.abstract}}</div>
        </div>
      </div>
    </div>
  </div>
</div>