<div class="training-container">
  <div class="training-manual-container">
  </div>
  <ion-card *ngFor="let d of documents">
    <ion-card-header>
      <ion-card-title>{{d.title}}</ion-card-title>
      <a [href]="d.url" target="_blank">Download the {{d.title}}</a>
    </ion-card-header>
    <ion-card-content style="height: 70vh !important;">
      <embed [src]="sanitizeUrl(d.url)" width="100%" height="100%">
    </ion-card-content>
  </ion-card>
  <div class="video-container" *ngFor="let v of videos">
    <ion-card class="video-card">
      <ion-card-header>
        <ion-card-title>
          {{v.title}}
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <div class="video" *ngIf="v.url">
          <video [ngClass]="['video']" controls (click)="toggleVideo()" #videoPlayer>
            <source [src]="v.url" type="video/mp4"/>
            Browser not supported
          </video>
        </div>
      </ion-card-content>
    </ion-card>
  </div>
</div>