import { Component, OnDestroy, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { HpiService, IIngredientParams } from '@app/core/hpi.service';
import { differenceInDays, sub, format } from 'date-fns';
import { map, Observable, Subscription } from 'rxjs';

import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faMicroscope } from '@fortawesome/free-solid-svg-icons'
import { IEffect } from '@app/models/hpi.model';
import { MatDialog } from '@angular/material/dialog';
import { DocDialogComponent } from '@app/modules/doc-dialog/doc-dialog.component';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit, OnDestroy {
  timelineTitle: string;

  faTwitter = faTwitter;
  faMicroscope = faMicroscope;
  funnelData: Partial<{
    new_count: number | string;
    new_social_count: number | string;
    science_count: number | string;
    new_science_count: number | string;
    relevant_social_count: number | string;
    relevant_science_count:number | string;
    science_total: number | string;
    social_total: number | string;
  }> = {
    social_total: '1 000 000 000',
    science_total: '187 000 000',
  }

  scienceDocsOptions: EChartsOption;
  socialDocsOptions: EChartsOption;
  topConcerns$: Observable<{social: IEffect[], science: IEffect[]}>;
  scienceScoreDelta = 0;
  socialScoreDelta = 0;
  sub: Subscription;

  constructor(
    public hpi: HpiService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.sub = this.hpi.selectedIngredient$.subscribe(sd => {
      if (!sd) {
        return;
      }
      this.constructFunnel();
      this.scienceScoreDelta = sd.science_score_delta;
      this.socialScoreDelta = sd.social_score_delta;
      switch (this.hpi.period) {
        case 'All':
          this.timelineTitle = 'Science and Market Timeline';
          break;
        case '1y':
          this.timelineTitle = 'Science and Market Timeline for the Last Year';
          break;
      }
    });
    // this.hpi.openDocDialog({
    //   ing_ids: ['PHMR65hWCKsEa3rFsz7aoG'],
    //   'period': 'All',
    //   title: 'test'
    // })
  }

  ngOnDestroy(): void {
    // alert('destroying summary');
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }

  async constructFunnel() {
    // const data = await this.hpi.funnel();
    const data = {
      new_science_count: 100,
      new_social_count: 100,
      relevant_science_count: 10,
      relevant_social_count: 10
    }
    Object.entries(data).forEach(([k, v]) => {
      this.funnelData[k] = v.toLocaleString().replace(',', ' ')
    });
    this.funnelData['new_count'] = (data.new_science_count + data.new_social_count).toLocaleString().replace(',', ' ')
    console.log('funnel data', this.funnelData);
  }

}
