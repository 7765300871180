import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { BackendService } from '@app/core/backend.service';
import { HpiService } from '@app/core/hpi.service';
import { LogService } from '@app/core/log.service';
import { IHpiDocument, SentimentOptions, TPeriod, TScienceOrSocial, TSentiment } from '@app/models/hpi.model';
import { BehaviorSubject, combineLatest, startWith, Subscription } from 'rxjs';

export interface IDocDialogParams {
  query_string?: string;
  start_date?: number;
  end_date?: number;
  period?: TPeriod;
  ing_ids?: string[];
  eff_ids?: string[];
  science_or_social?: TScienceOrSocial | 'both';
  title: string;
  sentiment?: TSentiment;
  sort?: 'date:asc' | 'date:desc' | 'citations';
  page_size?: number;
  page_index?: number;
  
}

@Component({
  selector: 'app-doc-dialog',
  templateUrl: './doc-dialog.component.html',
  styleUrls: ['./doc-dialog.component.scss'],
})
export class DocDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('paginator') paginator: MatPaginator;
  title = 'test';
  searchControl = new FormControl();
  subs: Subscription[] = [];
  documents: IHpiDocument[];
  loading = true;
  totalDocuments = 0;
  summary = false;
  summaryText = '';
  summaryLoading = false;
  summaryArticles = []
  viewMode: 'documents' | 'summary' = 'documents';

  scienceOrSocialOptions: {label: string, value: TScienceOrSocial | 'both'}[] = [
    {label: 'Science', value: 'science'},
    {label: 'Market', value: 'social'},
    {label: 'All', value: 'both'}
  ];
 
  sortOptions = [
    {label: 'Oldest First', value: 'date:asc'},
    {label: 'Newest First', value: 'date:desc'},
    {label: 'Likes/Citations', value: 'citations'}
  ]

  sentimentOptions = SentimentOptions;
  constructor(
    @Inject(MAT_DIALOG_DATA) public params: IDocDialogParams,
    public dialogRef: MatDialogRef<DocDialogComponent>,
    private backend: BackendService,
    public hpi: HpiService,
    private log: LogService,
  ) {
  }

  ngOnInit(): void {
    console.log('doc-dialog params', this.params);
    if (!this.params.sentiment) {
      this.params.sentiment = 'all';
    }
    if (!this.params.science_or_social) {
      this.params.science_or_social = 'both';
    }
    if (!this.params.sort) {
      this.params.sort = 'citations';
    }
    const sub = combineLatest([
      this.searchControl.valueChanges.pipe(startWith(null)),
    ]).subscribe(async ([searchInput]) => {
      await this.getDocuments();
    });
    this.subs.push(sub);
    this.log.writeLog(`loaded doc-dialog with params ${JSON.stringify(this.params)}`);
  }

  ngAfterViewInit(): void {
  }

  onParamChange(e) {
    this.documents = null;
    console.log('param changed', this.params);
    this.getDocuments();
    this.params.page_index = 0;
    this.summary = false;
    this.summaryText = '';
    this.summaryLoading = false;
    // this.paginator.pageIndex = 0;
  }

  async getDocuments() {
    this.documents = await this.backend.post_route<IHpiDocument[]>('search_documents', this.params);
    if (this.documents.length) {
      this.totalDocuments = this.documents[0]['totaldocuments'];
    } else {
      this.totalDocuments = 0;
    }
    console.log('documents', this.documents);
  }
  
  ngOnDestroy(): void {
    this.subs.forEach(s => {
      if (s) s.unsubscribe();
    })
  }

  handlePageEvent(e: PageEvent) {
    this.documents = null;
    console.log('paged', e);
    this.params.page_size = e.pageSize;
    this.params.page_index = e.pageIndex; 
    console.log(this.paginator);
    this.getDocuments();
  }
  
  clickDoc(doc: IHpiDocument) {
    console.log('clicked doc', doc.links);
    if (doc.science_or_social === 'science') {
      if (doc.links.length) {
        window.open(doc.links[0], '_blank')
      }
    } else {
      // window.open(`https://twitter.com/Twitter/status/${doc.doc_id}`, '_blank')
      if (doc.links && doc.links.length) {
        window.open(doc.links[0])
      }
    }
  }

  onViewModeChange(e) {
    console.log('view mode changed', e.value);
    this.viewMode = e.value;
    if (this.viewMode === 'summary') {
      this.summarize();
    }
  }

  async summarize() {
    this.summary = true
    this.summaryLoading = true
    let results = await this.backend.post_route<IHpiDocument[]>('cgpt_summarize', this.params);
    console.log(results)
    this.summaryText = results['summary']
    this.summaryArticles = results['news_to_show']
    this.summaryLoading = false
    console.log(this.summaryArticles)

  }
}
